import Firebase from 'firebase/app'
import 'firebase/auth'
import flagsmith from 'flagsmith'

import get from 'lodash/get'

import Cohere from 'cohere-js'
import LogRocket from 'logrocket'
import { H } from 'highlight.run'

import api from '@/helpers/api'

Cohere.init('ig8QeKP-iVdtZ4M3b-jbqps9')

if (!window.location.host.startsWith('localhost:')) {
  H.init('31ep4men', {
    environment: process.env.NODE_ENV,
    version: process.env.HEROKU_RELEASE_VERSION,
    enableStrictPrivacy: false,
    urlBlocklist: [
      'https://www.googleapis.com/identitytoolkit',
      'https://securetoken.googleapis.com',
      'https://r.lr-ingest.io/i',
      'https://firebasestorage.googleapis.com/v0/b/savvy-recording-events-live/',
      'https://pub.highlight.run',
    ],
  })
}

export async function postUserInit(user, self) {
  console.log('parent', parent)
  if (parent) {
    parent.postMessage(
      {
        event_name: 'auth.on_login',
        user: JSON.parse(JSON.stringify(user)),
      },
      '*'
    )
  }

  initCohere(user)
  initLogRocket(user)
  initHighlight(user)
  initFlagsmith(user, self)
  return Promise.all([
    initCommandBar(user),
    /* Xkit exported separately */
    // initXkit(),
  ])
  // initSentry()
}

export async function initXkit() {
  const user = Firebase.auth().currentUser

  if (user) {
    // const token = await user.getIdToken(true)
    const { data } = await api('/integrations/login')
    if (data && data.token) console.log('Xkit Session detected')
    if (window.xkit) {
      window.xkit.ready(async () => {
        try {
          await window.xkit.login(data.token)
          console.log('Initialized Xkit')
          return true
        } catch (e) {
          console.debug('Failed to login to Xkit', e)
          return false
        }
      })
    }
  } else {
    console.warn('No login session found. Logging out of Xkit.')
    if (window.xkit && typeof window.xkit.logout === 'function') await window.xkit.logout()
    return false
  }
}

export async function loginToXkit() {
  const user = Firebase.auth().currentUser

  if (user) {
    if (window.xkit) {
      const { data } = await api('/integrations/login')
      if (data && data.token) console.log('Xkit Session detected')
      try {
        await window.xkit.login(data.token)
        console.log('Initialized Xkit')
        return true
      } catch (e) {
        console.debug('Failed to login to Xkit', e)
        return false
      }
    }
  } else {
    console.warn('No login session found. Logging out of Xkit.')
    if (window.xkit && typeof window.xkit.logout === 'function') await window.xkit.logout()
    return false
  }
}

function initCohere(user) {
  Cohere.identify(user.uid, {
    displayName: user.auth.displayName || user.auth.email,
    email: user.auth.email,
  })
}

async function initLogRocket(user) {
  LogRocket.identify(user.uid, {
    name: user.displayName,
    email: get(user, 'auth.email'),
    photo: user.picture,
    image: user.picture,
    ...user,
  })
}

function initHighlight(user) {
  H.identify(get(user, 'auth.email'), {
    id: user.uid,
    name: user.displayName,
    image: user.picture,
  })
}

function initFlagsmith(user, self) {
  // Standard project initialisation
  flagsmith.init({
    environmentID: 'NjLax82aWarTtZF6i33gwb',
    onChange: () => {
      const featureIds = ['create_library_object_items']

      featureIds.forEach(featureId => {
        console.log('setFeature', featureId, flagsmith.hasFeature(featureId))
        console.log('setFeatureValue', featureId, flagsmith.getValue(featureId))
        self.$store.commit('setFeature', { featureId, value: flagsmith.hasFeature(featureId) })
        self.$store.commit('setFeatureValue', { featureId, value: flagsmith.getValue(featureId) })
      })
    },
  })
  // This will create a user in the dashboard if they don't already exist
  flagsmith.identify(user.uid, {
    name: user.displayName,
    email: user.auth.email,
    picture: user.picture,
  })
}

async function initCommandBar(user) {
  const senderDetails = { id: user.uid, email: user.auth.email, name: user.auth.displayName }
  await window.CommandBar.boot(user.uid, senderDetails)
}

// function initSentry() {
//   if (process.env.VUE_APP_SENTRY_DSN)
//     Sentry.setUser({ email: get(user, 'auth.email'), id: user.uid, username: user.displayName })
// }
